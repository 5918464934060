import React from 'react';
import { navigate, graphql } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';

export default function IndexPage() {
  const { language } = useI18next();

  React.useEffect(() => {
    navigate(language === 'en' ? '/home' : `/${language}/home`);
  }, [language]);

  return null;
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
